import React from 'react';
import { Redirect } from '@reach/router'

const IndexPage = () => {
    return (
        <Redirect noThrow to="/signin" />
        
    );
};

export default IndexPage;